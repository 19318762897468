import React from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';


ChartJS.register(...registerables);

function WidgetRenderer({ widget, index, removeWidget, isPreview, surveyResponses }) {
  const { type, title, questionText, surveyId, questionId } = widget;

  const processResponses = () => {
    if (!surveyResponses || !surveyResponses[surveyId]) {
      return { labels: [], data: [] };
    }

    const responses = surveyResponses[surveyId];
    const questionResponses = responses.map(r => r.responses[questionId]);
    const uniqueResponses = [...new Set(questionResponses)];
    const labels = uniqueResponses;
    const data = uniqueResponses.map(response => 
      questionResponses.filter(r => r === response).length
    );

    return { labels, data };
  };

  const { labels, data } = processResponses();

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: questionText,
        data: data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  const renderChart = () => {
    switch (type) {
      case 'bar':
        return <Bar data={chartData} options={options} />;
      case 'line':
        return <Line data={chartData} options={options} />;
      case 'pie':
        return <Pie data={chartData} options={options} />;
      default:
        return <p>Unsupported chart type</p>;
    }
  };

  return (
    <div className="h-full">
      {renderChart()}
    </div>
  );
}

export default WidgetRenderer;
