import React, { useState, useEffect } from 'react';
import { PlusCircle, MinusCircle } from 'lucide-react';

function QuestionDesigner({ addQuestion, editingQuestion, updateQuestion }) {
  const [questionType, setQuestionType] = useState(editingQuestion ? editingQuestion.type : 'multiple-choice');
  const [questionText, setQuestionText] = useState(editingQuestion ? editingQuestion.text : '');
  const [options, setOptions] = useState(editingQuestion ? editingQuestion.options : ['']);
  const [minRating, setMinRating] = useState(editingQuestion ? editingQuestion.minRating : 1);
  const [maxRating, setMaxRating] = useState(editingQuestion ? editingQuestion.maxRating : 5);

  useEffect(() => {
    if (editingQuestion) {
      setQuestionType(editingQuestion.type);
      setQuestionText(editingQuestion.text);
      setOptions(editingQuestion.options || ['']);
      setMinRating(editingQuestion.minRating || 1);
      setMaxRating(editingQuestion.maxRating || 5);
    }
  }, [editingQuestion]);

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const questionData = {
      type: questionType,
      text: questionText,
      options: questionType === 'multiple-choice' || questionType === 'checkbox-list' ? options : [],
      minRating: questionType === 'review' ? minRating : undefined,
      maxRating: questionType === 'review' ? maxRating : undefined,
    };

    if (editingQuestion) {
      updateQuestion({ ...editingQuestion, ...questionData });
    } else {
      addQuestion(questionData);
    }

    // Reset form
    setQuestionType('multiple-choice');
    setQuestionText('');
    setOptions(['']);
    setMinRating(1);
    setMaxRating(5);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4">
        {editingQuestion ? 'Edit Question' : 'Question Designer'}
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <select
          value={questionType}
          onChange={(e) => setQuestionType(e.target.value)}
          className="w-full p-2 border border-cool-blue-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
        >
          <option value="numeric">Numeric</option>
          <option value="short-text">Short Text</option>
          <option value="long-text">Long Text</option>
          <option value="checkbox-list">Checkbox List</option>
          <option value="multiple-choice">Multiple Choice</option>
          <option value="review">Review</option>
        </select>
        <input
          type="text"
          value={questionText}
          onChange={(e) => setQuestionText(e.target.value)}
          placeholder="Enter question text"
          required
          className="w-full p-2 border border-cool-blue-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
        />
        {(questionType === 'multiple-choice' || questionType === 'checkbox-list') && (
          <div className="space-y-2">
            {options.map((option, index) => (
              <div key={index} className="flex items-center space-x-2">
                <input
                  type="text"
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  placeholder={`Option ${index + 1}`}
                  required
                  className="flex-grow p-2 border border-cool-blue-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveOption(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <MinusCircle size={20} />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddOption}
              className="flex items-center text-cool-blue-600 hover:text-cool-blue-700"
            >
              <PlusCircle size={20} className="mr-1" /> Add Option
            </button>
          </div>
        )}
        {questionType === 'review' && (
          <div className="space-y-2">
            <div className="flex items-center space-x-4">
              <label className="flex-1">
                Min Rating:
                <input
                  type="number"
                  value={minRating}
                  onChange={(e) => setMinRating(Number(e.target.value))}
                  min="1"
                  max="10"
                  className="w-full p-2 border border-cool-blue-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
                />
              </label>
              <label className="flex-1">
                Max Rating:
                <input
                  type="number"
                  value={maxRating}
                  onChange={(e) => setMaxRating(Number(e.target.value))}
                  min="1"
                  max="10"
                  className="w-full p-2 border border-cool-blue-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
                />
              </label>
            </div>
          </div>
        )}
        <button
          type="submit"
          className="w-full bg-cool-blue-500 text-white py-2 px-4 rounded-md hover:bg-cool-blue-600 transition duration-300"
        >
          {editingQuestion ? 'Update Question' : 'Add Question'}
        </button>
      </form>
    </div>
  );
}

export default QuestionDesigner;