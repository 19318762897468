import React, { useState, useRef, useEffect } from 'react';
import { auth } from '../firebase/config';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  linkWithPopup,
  linkWithCredential,
  EmailAuthProvider
} from "firebase/auth";
import { signInAnonymously } from '../firebase/config';

function SignIn({ onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const dialogRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    try {
      if (auth.currentUser && auth.currentUser.isAnonymous) {
        const credential = EmailAuthProvider.credential(email, password);
        await linkWithCredential(auth.currentUser, credential);
      } else if (isRegistering) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      onClose();
    } catch (error) {
      console.error("Error with email authentication", error);
      alert(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      if (auth.currentUser && auth.currentUser.isAnonymous) {
        await linkWithPopup(auth.currentUser, provider);
      } else {
        await signInWithPopup(auth, provider);
      }
      onClose();
    } catch (error) {
      console.error("Error signing in with Google", error);
      alert("Failed to sign in with Google. Please try again.");
    }
  };

  const handleAnonymousSignIn = async () => {
    try {
      await signInAnonymously();
      onClose();
    } catch (error) {
      console.error("Error signing in anonymously", error);
      alert(error.message);
    }
  };

  const provider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google", error);
      alert("Failed to sign in with Google. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div ref={dialogRef} className="bg-white p-8 rounded-lg max-w-md w-full">
        <div className="flex justify-center mb-4">
          <button
            onClick={() => setIsRegistering(false)}
            className={`px-4 py-2 rounded-l-md ${!isRegistering ? 'bg-cool-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Sign In
          </button>
          <button
            onClick={() => setIsRegistering(true)}
            className={`px-4 py-2 rounded-r-md ${isRegistering ? 'bg-cool-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Register
          </button>
        </div>
        <form onSubmit={handleEmailAuth} className="space-y-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full p-2 border border-cool-blue-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full p-2 border border-cool-blue-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
            required
          />
          <button type="submit" className="w-full bg-cool-blue-500 text-white p-2 rounded hover:bg-cool-blue-600 transition duration-300">
            {isRegistering ? 'Register' : 'Sign In'}
          </button>
        </form>
        <div className="my-4 flex items-center">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="flex-shrink mx-4 text-gray-500">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
        <button 
          onClick={handleGoogleSignIn} 
          className="w-full bg-white text-gray-700 border border-gray-300 px-4 py-2 rounded-md hover:bg-gray-100 transition duration-300 flex items-center justify-center group"
        >
          <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
            <path
              fill="#4285F4"
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
            />
            <path
              fill="#34A853"
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
            />
            <path
              fill="#FBBC05"
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
            />
            <path
              fill="#EA4335"
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
            />
          </svg>
          <span className="group-hover:translate-x-1 transition-transform duration-300">Sign in with Google</span>
        </button>
        <div className="flex flex-col space-y-4 mt-6">
          <button
            onClick={handleAnonymousSignIn}
            className="w-full bg-gray-500 text-white p-2 rounded hover:bg-gray-600 transition duration-300 mt-2"
          >
            Continue as Guest
          </button>
          <button
            onClick={onClose}
            className="text-cool-blue-600 hover:text-cool-blue-800 transition duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
