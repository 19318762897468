import React, { useState } from 'react';

function WidgetDesigner({ addWidget, selectedQuestion }) {
  const [widgetType, setWidgetType] = useState('bar');
  const [widgetTitle, setWidgetTitle] = useState('');

  const handleAddWidget = () => {
    if (!selectedQuestion) {
      alert('Please select a question first');
      return;
    }
    addWidget({
      type: widgetType,
      title: widgetTitle,
      questionId: selectedQuestion.id,
      questionText: selectedQuestion.text
    });
    setWidgetTitle('');
  };

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Add Widget</h3>
      <div className="mb-4">
        <label className="block mb-2">Widget Type:</label>
        <select
          value={widgetType}
          onChange={(e) => setWidgetType(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="bar">Bar Chart</option>
          <option value="pie">Pie Chart</option>
          <option value="line">Line Chart</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Widget Title:</label>
        <input
          type="text"
          value={widgetTitle}
          onChange={(e) => setWidgetTitle(e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Enter widget title"
        />
      </div>
      <button
        onClick={handleAddWidget}
        className="bg-cool-blue-600 text-white px-4 py-2 rounded-md hover:bg-cool-blue-700 transition duration-300"
        disabled={!selectedQuestion}
      >
        Add Widget
      </button>
    </div>
  );
}

export default WidgetDesigner;
