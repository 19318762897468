import React from 'react';
import { Trash2, GripVertical } from 'lucide-react';
import { Draggable } from 'react-beautiful-dnd';

function QuestionRenderer({ question, index, removeQuestion, isPreview, handleResponseChange, responses }) {
  const renderQuestionInput = (question) => {
    const isDisabled = isPreview && !question.allowInteraction;
    
    switch (question.type) {
      case 'multiple-choice':
        return (
          <div className="space-y-2">
            {question.options.map((option, optionIndex) => (
              <label key={optionIndex} className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name={`question-${question.id}`}
                  value={option}
                  onChange={(e) => handleResponseChange(question.id, e.target.value)}
                  required={!isPreview}
                  className="form-radio text-cool-blue-600 focus:ring-cool-blue-500"
                  disabled={isDisabled}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        );
      case 'checkbox-list':
        return (
          <div className="space-y-2">
            {question.options.map((option, optionIndex) => (
              <label key={optionIndex} className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  value={option}
                  onChange={(e) => {
                    if (!isDisabled) {
                      const currentResponses = responses[question.id] || [];
                      const updatedResponses = e.target.checked
                        ? [...currentResponses, option]
                        : currentResponses.filter(item => item !== option);
                      handleResponseChange(question.id, updatedResponses);
                    }
                  }}
                  className="form-checkbox text-cool-blue-600 focus:ring-cool-blue-500"
                  disabled={isDisabled}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        );
      case 'short-text':
        return (
          <input
            type="text"
            onChange={(e) => handleResponseChange(question.id, e.target.value)}
            required={!isPreview}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
            placeholder={isPreview ? "Short answer text" : "Enter your answer"}
            disabled={isDisabled}
          />
        );
      case 'long-text':
        return (
          <textarea
            onChange={(e) => handleResponseChange(question.id, e.target.value)}
            required={!isPreview}
            rows="3"
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
            placeholder={isPreview ? "Long answer text" : "Enter your answer"}
            disabled={isDisabled}
          />
        );
      case 'numeric':
        return (
          <input
            type="number"
            onChange={(e) => handleResponseChange(question.id, e.target.value)}
            required={!isPreview}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-cool-blue-500 focus:border-cool-blue-500"
            placeholder={isPreview ? "Enter a number" : "Enter your numeric answer"}
            disabled={isDisabled}
          />
        );
      case 'review':
        return (
          <div className="flex items-center">
            {[...Array(question.maxRating)].map((_, index) => {
              const ratingValue = index + 1;
              return (
                <button
                  key={index}
                  type="button"
                  className={`text-3xl focus:outline-none ${
                    ratingValue <= (responses[question.id] || 0)
                      ? 'text-yellow-400'
                      : 'text-gray-300'
                  }`}
                  onClick={() => !isDisabled && handleResponseChange(question.id, ratingValue)}
                  disabled={isDisabled}
                >
                  ★
                </button>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  };

  const content = (
    <div className={`bg-white ${isPreview ? 'shadow-md' : 'shadow-lg'} rounded-lg p-6`}>
      <label className="block text-xl font-semibold mb-4 text-gray-800 text-left">
        {isPreview ? '' : `${index + 1}. `}{question.text}
      </label>
      {renderQuestionInput(question)}
    </div>
  );

  if (isPreview) {
    return (
      <Draggable draggableId={question.id.toString()} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className="flex items-start border border-gray-200 rounded-md p-4 relative"
            style={{
              ...provided.draggableProps.style,
              background: snapshot.isDragging ? 'lightgreen' : 'white',
            }}
          >
            <div
              {...provided.dragHandleProps}
              className="cursor-move pr-4 flex items-center h-full"
            >
              <GripVertical size={20} className="text-gray-400" />
            </div>
            <div className="flex-grow">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-medium text-left">{question.text}</h3>
                <button
                  onClick={() => removeQuestion(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 size={20} />
                </button>
              </div>
              <div className="text-left">{renderQuestionInput(question)}</div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }

  return content;
}

export default QuestionRenderer;