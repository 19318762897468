import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase/config';
import { collection, addDoc, doc, setDoc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { Save,Trash2 } from 'lucide-react';
import { DragDropContext } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../utils/StrictModeDroppable';
import WidgetDesigner from './WidgetDesigner';
import WidgetRenderer from './WidgetRenderer';

const DashboardDesigner = forwardRef(({ createNewDashboard, setDashboardId }, ref) => {
  const { dashboardId } = useParams();
  const navigate = useNavigate();
  const [widgets, setWidgets] = useState([]);
  const [saveStatus, setSaveStatus] = useState('idle');
  const dashboardPreviewRef = React.useRef(null);
  const [dashboardTitle, setDashboardTitle] = useState(`New Dashboard (${new Date().toLocaleDateString()})`);
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [surveyResponses, setSurveyResponses] = useState({});
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [surveysLoading, setSurveysLoading] = useState(true);

  const fetchSurveyResponses = useCallback(async (surveyId) => {
    if (surveyResponses[surveyId]) return; // Avoid refetching if already loaded
    try {
      const q = query(collection(db, 'survey_responses'), where('surveyId', '==', surveyId));
      const querySnapshot = await getDocs(q);
      const responseData = querySnapshot.docs.map(doc => doc.data());
      setSurveyResponses(prevResponses => ({
        ...prevResponses,
        [surveyId]: responseData
      }));
    } catch (error) {
      console.error('Error fetching survey responses:', error);
    }
  }, [surveyResponses]);

  const fetchDashboard = useCallback(async () => {
    setDashboardLoading(true);
    try {
      const dashboardDoc = await getDoc(doc(db, 'dashboards', dashboardId));
      if (dashboardDoc.exists()) {
        const data = dashboardDoc.data();
        setDashboardTitle(data.title);
        setWidgets(data.widgets || []);
        
        // Fetch survey responses for each widget
        const uniqueSurveyIds = [...new Set(data.widgets.map(widget => widget.surveyId))];
        for (const surveyId of uniqueSurveyIds) {
          await fetchSurveyResponses(surveyId);
        }
      }
    } catch (error) {
      console.error('Error fetching dashboard:', error);
    } finally {
      setDashboardLoading(false);
    }
  }, [dashboardId, fetchSurveyResponses]);

  useEffect(() => {
    if (dashboardId && dashboardId !== 'new') {
      fetchDashboard();
    }
  }, [dashboardId, fetchDashboard]);

  useEffect(() => {
    fetchUserSurveys();
  }, []);

  const fetchUserSurveys = async () => {
    setSurveysLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      const surveysRef = collection(db, 'surveys');
      const q = query(surveysRef, where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const surveyData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSurveys(surveyData);
    } catch (error) {
      console.error('Error fetching surveys:', error);
      alert('Failed to load surveys. Please try again.');
    } finally {
      setSurveysLoading(false);
    }
  };

  const handleSurveyChange = (e) => {
    const survey = surveys.find(s => s.id === e.target.value);
    setSelectedSurvey(survey);
    setSelectedQuestion(null);
    if (survey && !surveyResponses[survey.id]) {
      fetchSurveyResponses(survey.id);
    }
  };

  const handleQuestionChange = (e) => {
    const question = selectedSurvey.questions.find(q => q.id === e.target.value);
    setSelectedQuestion(question);
  };

  const addWidget = (newWidget) => {
    const widgetWithId = { 
      ...newWidget, 
      id: Date.now().toString(),
      surveyId: selectedSurvey.id,
      questionId: selectedQuestion.id
    };
    setWidgets([...widgets, widgetWithId]);
  };

  const removeWidget = (index) => {
    const updatedWidgets = widgets.filter((_, i) => i !== index);
    setWidgets(updatedWidgets);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedWidgets = Array.from(widgets);
    const [reorderedItem] = reorderedWidgets.splice(result.source.index, 1);
    reorderedWidgets.splice(result.destination.index, 0, reorderedItem);

    setWidgets(reorderedWidgets);
  };

  const saveDashboard = async () => {
    setSaveStatus('saving');
    try {
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      const dashboardData = {
        title: dashboardTitle,
        widgets: widgets,
        updatedAt: new Date(),
        userId: auth.currentUser.uid,
      };
      if (dashboardId && dashboardId !== 'new') {
        await setDoc(doc(db, 'dashboards', dashboardId), dashboardData, { merge: true });
      } else {
        const docRef = await addDoc(collection(db, 'dashboards'), {
          ...dashboardData,
          createdAt: new Date(),
        });
        if (typeof setDashboardId === 'function') {
          setDashboardId(docRef.id);
        } else {
          console.warn('setDashboardId is not a function');
        }
        navigate(`/dashboards/${docRef.id}`);
      }
      setSaveStatus('saved');
      setTimeout(() => setSaveStatus('idle'), 2000);
    } catch (error) {
      console.error('Error saving dashboard:', error);
      alert(`Failed to save dashboard: ${error.message}. Please try again.`);
      setSaveStatus('idle');
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <button
        onClick={() => navigate('/dashboards')}
        className="mb-4 text-cool-blue-600 hover:text-cool-blue-700"
      >
        &larr; Back to Dashboards
      </button>
      <div className="flex flex-1 overflow-hidden">
        <div className="w-1/3 p-6 overflow-y-auto sticky top-0 h-screen">
          <input
            type="text"
            value={dashboardTitle}
            onChange={(e) => setDashboardTitle(e.target.value)}
            className="w-full mb-4 text-3xl font-bold p-2 border-b-2 border-cool-blue-300 focus:outline-none focus:border-cool-blue-500"
          />
          <div className="mb-6 flex space-x-4">
            <button
              onClick={saveDashboard}
              disabled={saveStatus === 'saving'}
              className={`flex-1 flex items-center justify-center px-4 py-2 rounded-md text-white transition duration-300 ${
                saveStatus === 'saving' ? 'bg-cool-blue-400 cursor-not-allowed' : 
                saveStatus === 'saved' ? 'bg-green-500 hover:bg-green-600' : 
                'bg-cool-blue-600 hover:bg-cool-blue-700'
              }`}
            >
              <Save size={20} className={`mr-2 ${saveStatus === 'saving' ? 'animate-spin' : ''}`} />
              {saveStatus === 'saving' ? 'Saving...' : saveStatus === 'saved' ? 'Saved!' : 'Save Dashboard'}
            </button>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Select Survey:</label>
            {surveysLoading ? (
              <p>Loading surveys...</p>
            ) : surveys.length === 0 ? (
              <p>No surveys available. Please create a survey first.</p>
            ) : (
              <select
                value={selectedSurvey?.id || ''}
                onChange={handleSurveyChange}
                className="w-full p-2 border rounded"
              >
                <option value="">Select a survey</option>
                {surveys.map((survey) => (
                  <option key={survey.id} value={survey.id}>
                    {survey.title}
                  </option>
                ))}
              </select>
            )}
          </div>
          {selectedSurvey && (
            <div className="mb-4">
              <label className="block mb-2">Select Question:</label>
              <select
                value={selectedQuestion?.id || ''}
                onChange={handleQuestionChange}
                className="w-full p-2 border rounded"
              >
                <option value="">Select a question</option>
                {selectedSurvey.questions.map((question) => (
                  <option key={question.id} value={question.id}>
                    {question.text}
                  </option>
                ))}
              </select>
            </div>
          )}
          <WidgetDesigner addWidget={addWidget} selectedQuestion={selectedQuestion} />
        </div>
        <div 
          ref={dashboardPreviewRef}
          className="w-2/3 p-6 overflow-y-auto h-screen"
        >
          <h2 className="text-2xl font-semibold mb-4">Dashboard Preview</h2>
          {dashboardLoading ? (
            <p className="text-gray-500">Loading dashboard...</p>
          ) : widgets.length === 0 ? (
            <p className="text-gray-500">No widgets added yet.</p>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <StrictModeDroppable droppableId="widget-list">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {widgets.map((widget, index) => (
                      <div key={widget.id} className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
                        <div className="flex justify-between items-center mb-2">
                          <h3 className="text-lg font-medium">{widget.title}</h3>
                          <button
                            onClick={() => removeWidget(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                        <p className="text-sm text-gray-600 mb-2">Data Source: {widget.questionText}</p>
                        <div className="h-48">
                          <WidgetRenderer
                            widget={widget}
                            index={index}
                            removeWidget={removeWidget}
                            isPreview={true}
                            surveyResponses={surveyResponses}
                          />
                        </div>
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          )}
        </div>
      </div>
    </div>
  );
});

export default DashboardDesigner;