import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';

function DashboardList({ user }) {
  const [dashboards, setDashboards] = useState([]);

  useEffect(() => {
    const fetchDashboards = async () => {
      if (user) {
        const q = query(collection(db, 'dashboards'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const dashboardData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDashboards(dashboardData);
      }
    };

    fetchDashboards();
  }, [user]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Your Dashboards</h1>
      <Link to="/dashboards/new" className="bg-cool-blue-500 text-white px-4 py-2 rounded-md hover:bg-cool-blue-600 transition duration-300">
        Create New Dashboard
      </Link>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {dashboards.map((dashboard) => (
          <Link 
            key={dashboard.id}
            to={`/dashboards/${dashboard.id}`}
            className="bg-white border border-gray-200 rounded-lg p-6 hover:shadow-md transition duration-300"
          >
            <h3 className="text-lg font-semibold mb-2">{dashboard.title}</h3>
            <p className="text-sm text-gray-500 mb-2">
              Created: {dashboard.createdAt ? new Date(dashboard.createdAt.seconds * 1000).toLocaleDateString() : 'Unknown'}
            </p>
            <p className="text-sm text-gray-500">
              Widgets: {dashboard.widgets ? dashboard.widgets.length : 0}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default DashboardList;
