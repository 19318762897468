import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase/config';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { Bar, Pie} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from 'chart.js';
import { CSVLink } from "react-csv";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

function SurveyAnalytics() {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [chartType, setChartType] = useState('bar');
  // eslint-disable-next-line no-unused-vars
  const [startDate, setStartDate] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchSurveyAndResponses = async () => {
      try {
        // Fetch survey
        const surveyDoc = await getDoc(doc(db, 'surveys', surveyId));
        if (surveyDoc.exists()) {
          const surveyData = surveyDoc.data();
          setSurvey(surveyData);
          setSelectedQuestion(surveyData.questions[0]);
        } else {
          setError('Survey not found');
        }

        // Fetch responses
        const q = query(collection(db, 'survey_responses'), where('surveyId', '==', surveyId));
        const querySnapshot = await getDocs(q);
        const responseData = querySnapshot.docs.map(doc => doc.data());
        setResponses(responseData);
      } catch (error) {
        console.error('Error fetching survey and responses:', error);
        setError('Error loading survey and responses');
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyAndResponses();
  }, [surveyId]);

  const processAllResponses = () => {
    const responseCounts = {};

    survey.questions.forEach(question => {
      responseCounts[question.id] = {};
      responses.forEach(response => {
        const answer = response.responses[question.id];
        if (Array.isArray(answer)) {
          answer.forEach(option => {
            responseCounts[question.id][option] = (responseCounts[question.id][option] || 0) + 1;
          });
        } else if (answer) {
          responseCounts[question.id][answer] = (responseCounts[question.id][answer] || 0) + 1;
        }
      });
    });

    return responseCounts;
  };

  const renderAllCharts = () => {
    if (!survey || responses.length === 0) return null;

    const allResponseCounts = processAllResponses();

    return (
      <div className="max-h-[600px] overflow-y-auto pr-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {survey.questions.map((question, index) => {
            const responseCounts = allResponseCounts[question.id];
            const labels = Object.keys(responseCounts);
            const data = Object.values(responseCounts);

            const chartData = {
              labels,
              datasets: [
                {
                  label: 'Responses',
                  data,
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
              ],
            };

            const options = {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: `Q${index + 1}: ${question.text}`,
                },
              },
            };

            return (
              <div key={question.id} className="bg-white p-4 rounded-lg shadow">
                <div className="h-64">
                  {question.type === 'multiple-choice' || question.type === 'checkbox' ? (
                    <Pie data={chartData} options={options} />
                  ) : (
                    <Bar data={chartData} options={options} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderDataTable = () => {
    if (!survey || responses.length === 0) return null;

    return (
      <div className="overflow-x-auto">
        <table className="w-full mt-4 border-collapse border border-cool-blue-200">
          <thead className="bg-cool-blue-100">
            <tr>
              <th className="border border-cool-blue-200 p-2 w-16">S.No</th>
              {survey.questions.map((question, index) => (
                <th key={question.id} className="border border-cool-blue-200 p-2">
                  Q{index + 1}: {question.text}
                </th>
              ))}
              <th className="border border-cool-blue-200 p-2">Date</th>
            </tr>
          </thead>
          <tbody>
            {responses.map((response, index) => (
              <tr key={response.id || index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="border border-cool-blue-200 p-2 text-center">{index + 1}</td>
                {survey.questions.map(question => (
                  <td key={question.id} className="border border-cool-blue-200 p-2">
                    {Array.isArray(response.responses[question.id])
                      ? response.responses[question.id].join(', ')
                      : response.responses[question.id] || '-'}
                  </td>
                ))}
                <td className="border border-cool-blue-200 p-2 text-center">
                  {response.submittedAt ? response.submittedAt.toDate().toLocaleDateString() : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const prepareDataForDownload = () => {
    if (!survey || responses.length === 0) return [];

    const headers = [
      "S.No",
      ...survey.questions.map((q, index) => `Q${index + 1}: ${q.text}`),
      "Date"
    ];
    const data = [headers];

    responses.forEach((response, index) => {
      const row = [
        index + 1,
        ...survey.questions.map(question => {
          const answer = response.responses[question.id];
          return Array.isArray(answer) ? answer.join(', ') : (answer || '-');
        }),
        response.submittedAt ? response.submittedAt.toDate().toLocaleDateString() : '-'
      ];
      data.push(row);
    });

    return data;
  };

  const filteredResponses = responses.filter(response => {
    if (!startDate && !endDate) return true;
    const responseDate = response.submittedAt.toDate();
    if (startDate && !endDate) return responseDate >= startDate;
    if (!startDate && endDate) return responseDate <= endDate;
    return responseDate >= startDate && responseDate <= endDate;
  });

  if (loading) return <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-cool-blue-500"></div>
  </div>;
  if (error) return <div className="text-red-500 text-center text-xl mt-10">{error}</div>;
  if (responses.length === 0) {
    return <div className="text-cool-blue-500 text-center text-xl mt-10">No responses yet for this survey.</div>;
  }

  return (
    <div className="container mx-auto p-4 bg-gray-50">
      <h1 className="text-4xl font-bold mb-6 text-cool-blue-600 text-center">Survey Analytics</h1>
      <div className="bg-white p-6 rounded-lg shadow mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-cool-blue-600">Response Overview</h2>
        <p className="text-lg mb-4">Total Responses: <span className="font-bold text-cool-blue-500">{filteredResponses.length}</span></p>
        {renderAllCharts()}
      </div>
      <div className="bg-white p-6 rounded-lg shadow mb-6 overflow-x-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-cool-blue-600">Response Data</h2>
          <CSVLink
            data={prepareDataForDownload()}
            filename={`survey_responses_${surveyId}_full_data.csv`}
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
          >
            Download CSV
          </CSVLink>
        </div>
        {renderDataTable()}
      </div>
    </div>
  );
}

export default SurveyAnalytics;
