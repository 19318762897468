import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence, signInAnonymously as firebaseSignInAnonymously } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD6z2zsI7FUFcd5At21Oe9wtrPICiNANYY",
  authDomain: "simpleproject1-bc7f1.firebaseapp.com",
  projectId: "simpleproject1-bc7f1",
  storageBucket: "simpleproject1-bc7f1.appspot.com",
  messagingSenderId: "719022343354",
  appId: "1:719022343354:web:b2bf1920a47de2e13dee83",
  measurementId: "G-K1WWYH2MML"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);
export const db = getFirestore(app);
export const signInAnonymously = () => firebaseSignInAnonymously(auth);
