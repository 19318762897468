import React, { useState, useEffect } from 'react';
import { auth } from '../firebase/config';
import { signOut } from "firebase/auth";
import { LogOut, LogIn, User, Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

function Navbar({ user, setShowSignIn, handleCreateNewSurvey, handleAnonymousSignIn }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const handleSignOut = () => {
    signOut(auth).catch((error) => {
      console.error("Error signing out", error);
    });
  };

  const formatEmail = (email) => {
    if (email) {
      return email.split('@')[0];
    }
    return email;
  };

  return (
    <nav className="bg-cool-blue-950 shadow-lg">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <h1 className="text-white text-2xl font-bold">Survey Designer</h1>
          <div className="hidden md:flex items-center space-x-6">
            {user && (
              <span className="text-white font-semibold bg-cool-blue-700 px-3 py-1 rounded-full">
                {user.isAnonymous ? 'Anonymous User' : formatEmail(user.email)}
              </span>
            )}
            <Link to="/surveys" className="text-white hover:text-cool-blue-200 transition duration-300">Surveys</Link>
            <Link to="/dashboards" className="text-white hover:text-cool-blue-200 transition duration-300">Dashboards</Link>
            {user ? (
              <Tippy content="Sign Out">
                <button
                  onClick={handleSignOut}
                  className="bg-red-500 text-white px-4 py-2 rounded-full flex items-center hover:bg-red-600 transition duration-300"
                >
                  <LogOut size={20} className="mr-2" />
                  Sign Out
                </button>
              </Tippy>
            ) : (
              <>
                <button
                  onClick={() => setShowSignIn(true)}
                  className="bg-green-500 text-white px-4 py-2 rounded-full flex items-center hover:bg-green-600 transition duration-300"
                >
                  <LogIn size={20} className="mr-2" />
                  Sign In
                </button>
                <button
                  onClick={handleAnonymousSignIn}
                  className="bg-blue-500 text-white px-4 py-2 rounded-full flex items-center hover:bg-blue-600 transition duration-300"
                >
                  <User size={20} className="mr-2" />
                  Guest
                </button>
              </>
            )}
          </div>
          <div className="md:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-white">
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'} bg-blue-950 shadow-lg`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {user && (
            <span className="text-white font-semibold block px-3 py-2">
              {user.isAnonymous ? 'Anonymous User' : formatEmail(user.email)}
            </span>
          )}
          <Link to="/surveys" className="text-white block px-3 py-2 rounded-md hover:bg-cool-blue-600 transition duration-300">Surveys</Link>
          <Link to="/dashboards" className="text-white block px-3 py-2 rounded-md hover:bg-cool-blue-600 transition duration-300">Dashboards</Link>
          {user ? (
            <button
              onClick={handleSignOut}
              className="w-full text-left text-white px-3 py-2 rounded-md hover:bg-red-600 transition duration-300"
            >
              <LogOut size={20} className="inline mr-2" />
              Sign Out
            </button>
          ) : (
            <>
              <button
                onClick={() => setShowSignIn(true)}
                className="w-full text-left text-white px-3 py-2 rounded-md hover:bg-green-600 transition duration-300"
              >
                <LogIn size={20} className="inline mr-2" />
                Sign In
              </button>
              <button
                onClick={handleAnonymousSignIn}
                className="w-full text-left text-white px-3 py-2 rounded-md hover:bg-blue-600 transition duration-300"
              >
                <User size={20} className="inline mr-2" />
                Continue as Guest
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;