import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import SurveyDesigner from './components/SurveyDesigner';
import SignIn from './components/SignIn';
import Navbar from './components/Navbar';
import { auth, signInAnonymously } from './firebase/config';
import { onAuthStateChanged } from "firebase/auth";
import { db } from './firebase/config';
import { collection, addDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import SurveyList from './components/SurveyList';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SurveyViewer from './components/SurveyViewer';
import SurveyAnalytics from './components/SurveyAnalytics';
import DashboardDesigner from './components/DashboardDesigner';
import DashboardList from './components/DashboardList';
import 'tippy.js/dist/tippy.css';

function App() {
  const [user, setUser] = useState(null);
  const [showSignIn, setShowSignIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [setSurveyId] = useState(null);
  const [dashboardId, setDashboardId] = useState(null);
  const surveyDesignerRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const createNewSurvey = async () => {
    const surveyId = uuidv4();
    const surveysCollection = collection(db, 'surveys');
    const newSurveyRef = await addDoc(surveysCollection, {
      id: surveyId,
      userId: user.uid,
      title: `New Survey (${new Date().toLocaleDateString()})`,
      questions: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    return newSurveyRef.id;
  };

  const createNewDashboard = async () => {
    const dashboardsCollection = collection(db, 'dashboards');
    const newDashboardRef = await addDoc(dashboardsCollection, {
      userId: user.uid,
      title: `New Dashboard (${new Date().toLocaleDateString()})`,
      widgets: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    return newDashboardRef.id;
  };

  const handleAnonymousSignIn = async () => {
    try {
      await signInAnonymously();
    } catch (error) {
      console.error('Error signing in anonymously:', error);
      alert('Failed to sign in anonymously. Please try again.');
    }
  };

  return (
    <Router>
      <div className="App">
        <Navbar user={user} setShowSignIn={setShowSignIn} handleAnonymousSignIn={handleAnonymousSignIn} />
        {loading ? (
          <div className="flex items-center justify-center h-screen">
            <p className="text-xl">Loading...</p>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={user ? <SurveyList user={user} /> : <Navigate to="/signin" />} />
            <Route 
              path="/surveys/new" 
              element={user ? <SurveyDesigner ref={surveyDesignerRef} createNewSurvey={createNewSurvey} setSurveyId={setSurveyId} user={user} /> : <Navigate to="/signin" />} 
            />
            <Route 
              path="/surveys/:surveyId/edit" 
              element={user ? <SurveyDesigner ref={surveyDesignerRef} setSurveyId={setSurveyId} user={user} /> : <Navigate to="/signin" />} 
            />
            <Route path="/s/:surveyId" element={<SurveyViewer />} />
            <Route path="/signin" element={!user ? <SignIn onClose={() => setShowSignIn(false)} /> : <Navigate to="/" />} />
            <Route path="/surveys/:surveyId/analytics" element={user ? <SurveyAnalytics /> : <Navigate to="/signin" />} />
            <Route path="/dashboards/:dashboardId" element={user ? <DashboardDesigner createNewDashboard={createNewDashboard} setDashboardId={setDashboardId} /> : <Navigate to="/signin" />} />
            <Route path="/dashboards" element={user ? <DashboardList user={user} /> : <Navigate to="/signin" />} />
            <Route path="/dashboards/new" element={user ? <DashboardDesigner createNewDashboard={createNewDashboard} setDashboardId={setDashboardId} /> : <Navigate to="/signin" />} />
            <Route path="/surveys" element={user ? <SurveyList user={user} /> : <Navigate to="/signin" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
        {showSignIn && <SignIn onClose={() => setShowSignIn(false)} />}
      </div>
    </Router>
  );
}

export default App;