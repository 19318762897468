import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PlusCircle } from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase/config';
import { BarChart } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

function SurveyList({ user }) {
  const [surveys, setSurveys] = useState([]);
  const [copiedLink, setCopiedLink] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSurveys();
  }, []);

  const fetchSurveys = async () => {
    try {
      setLoading(true);
      const surveysRef = collection(db, 'surveys');
      const q = query(surveysRef, where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const surveyData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const survey = { id: doc.id, ...doc.data() };
          const responseCountSnapshot = await getDocs(query(collection(db, 'survey_responses'), where('surveyId', '==', doc.id)));
          survey.responseCount = responseCountSnapshot.size;
          return survey;
        })
      );
      setSurveys(surveyData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching surveys:', error);
    }
  };

  const navigate = useNavigate();

  const analyzeResponses = (surveyId) => {
    navigate(`/surveys/${surveyId}/analytics`);
  };

  const copyShareLink = (surveyId) => {
    const shareLink = `${window.location.origin}/s/${surveyId}`;
    navigator.clipboard.writeText(shareLink).then(() => {
      setCopiedLink(surveyId);
      setTimeout(() => setCopiedLink(null), 3000); // Reset after 3 seconds
    });
  };

  const navigateToSurveyDesigner = (surveyId) => {
    navigate(surveyId ? `/surveys/${surveyId}/edit` : '/surveys/new');
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Your Surveys</h1>
      {loading ? (
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-cool-blue-500"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <Link 
            to="/surveys/new"
            className="bg-cool-blue-100 border-2 border-dashed border-cool-blue-500 rounded-lg p-6 flex items-center justify-center cursor-pointer hover:bg-cool-blue-200 transition duration-300"
          >
            <PlusCircle size={24} className="mr-2 text-cool-blue-500" />
            <span className="text-lg font-semibold text-cool-blue-500">New Survey</span>
          </Link>
          {surveys.map((survey) => (
            <div key={survey.id} className="bg-white border border-gray-200 rounded-lg p-6 flex flex-col justify-between">
              <div>
                <h3 className="text-lg font-semibold mb-2">{survey.title}</h3>
                <p className="text-sm text-gray-500 mb-2">
                  Created: {survey.createdAt ? new Date(survey.createdAt.seconds * 1000).toLocaleDateString() : 'Unknown'}
                </p>
                <p className="text-sm text-gray-500 mb-4">
                  Responses: {survey.responseCount || 0}
                </p>
              </div>
              <div className="flex flex-col space-y-2">
                <button
                  onClick={() => navigateToSurveyDesigner(survey.id)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => analyzeResponses(survey.id)}
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
                >
                  View Analytics
                </button>
                <button
                  onClick={() => copyShareLink(survey.id)}
                  className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600 transition duration-300"
                >
                  {copiedLink === survey.id ? 'Link Copied!' : 'Share Survey'}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SurveyList;