import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, addDoc, collection} from 'firebase/firestore';
import { db } from '../firebase/config';
import QuestionRenderer from './QuestionRenderer';
import { auth } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import SignIn from './SignIn';

function SurveyViewer() {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const [responses, setResponses] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const navigate = useNavigate();

  const navigateToRegister = () => {
    navigate('/register');
  };

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const surveyDoc = await getDoc(doc(db, 'surveys', surveyId));
        if (surveyDoc.exists()) {
          setSurvey(surveyDoc.data());
        } else {
          setError('Survey not found');
        }
      } catch (error) {
        console.error('Error fetching survey:', error);
        setError('Error loading survey');
      } finally {
        setLoading(false);
      }
    };

    fetchSurvey();
  }, [surveyId]);

  const handleResponseChange = (questionId, value) => {
    setResponses(prev => ({ ...prev, [questionId]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responseData = {
        surveyId,
        responses,
        submittedAt: new Date(),
        userId: auth.currentUser ? auth.currentUser.uid : 'anonymous',
      };
      await addDoc(collection(db, 'survey_responses'), responseData);
      setHasSubmitted(true);
      setResponses({});
    } catch (error) {
      console.error('Error submitting survey:', error);
      alert('Failed to submit survey. Please try again.');
    }
  };

  const handleCreateSurvey = () => {
    setShowSignIn(true);
  };

  if (loading) {
    return <div className="container mx-auto p-4 text-center">Loading survey...</div>;
  }

  if (error) {
    return <div className="container mx-auto p-4 text-center text-red-500">{error}</div>;
  }

  if (hasSubmitted) {
    return (
      <div className="container mx-auto p-4 h-screen flex flex-col items-center justify-center">
        <h2 className="text-3xl font-bold mb-6 text-center text-cool-blue-600">Thank you for submitting the survey!</h2>
        <button
          onClick={handleCreateSurvey}
          className="bg-cool-blue-500 text-white px-6 py-3 rounded-md hover:bg-cool-blue-600 transition duration-300 mt-6 text-xl font-bold"
        >
          Create Your Survey
        </button>
        {showSignIn && <SignIn onClose={() => setShowSignIn(false)} />}
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 max-w-2xl">
      <h1 className="text-3xl font-bold mb-6 text-center text-cool-blue-600">{survey.title}</h1>
      {hasSubmitted ? (
        <div className="text-center text-xl text-cool-blue-600">
          Thank you for your response!
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-8">
          {survey.questions.map((question, index) => (
            <QuestionRenderer
              key={question.id}
              question={question}
              index={index}
              removeQuestion={() => {}}
              isPreview={false}
              handleResponseChange={handleResponseChange}
              responses={responses}
            />
          ))}
          <div className="flex justify-center">
            <button 
              type="submit" 
              className="bg-cool-blue-600 text-white px-8 py-3 rounded-lg hover:bg-cool-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-cool-blue-500 focus:ring-opacity-50"
            >
              Submit Survey
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default SurveyViewer;
