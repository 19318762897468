import React from 'react';
import { Trash2, GripVertical, Edit } from 'lucide-react';
import { Draggable } from 'react-beautiful-dnd';

function QuestionPreview({ question, index, removeQuestion, isPreview, handleResponseChange, responses, onEdit }) {
  const renderQuestionInput = (question) => {
    switch (question.type) {
      case 'multiple-choice':
        return (
          <div className="space-y-2">
            {question.options.map((option, optionIndex) => (
              <label key={optionIndex} className="flex items-center space-x-2">
                <input type="radio" name={`question-${question.id}`} value={option} className="form-radio" />
                <span>{option}</span>
              </label>
            ))}
          </div>
        );
      case 'checkbox-list':
        return (
          <div className="space-y-2">
            {question.options.map((option, optionIndex) => (
              <label key={optionIndex} className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox" />
                <span>{option}</span>
              </label>
            ))}
          </div>
        );
      case 'short-text':
        return (
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter your answer"
          />
        );
      case 'long-text':
        return (
          <textarea
            className="w-full p-2 border border-gray-300 rounded-md"
            rows="3"
            placeholder="Enter your answer"
          />
        );
      case 'numeric':
        return (
          <input
            type="number"
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter a number"
          />
        );
      case 'review':
        return (
          <div className="flex items-center">
            {[...Array(question.maxRating)].map((_, index) => {
              const ratingValue = index + 1;
              return (
                <button
                  key={index}
                  type="button"
                  className={`text-3xl focus:outline-none ${
                    ratingValue <= (responses[question.id] || 0)
                      ? 'text-yellow-400'
                      : 'text-gray-300'
                  }`}
                  onClick={() => handleResponseChange(question.id, ratingValue)}
                >
                  ★
                </button>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Draggable draggableId={question.id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="flex items-start border border-gray-200 rounded-md p-4 relative"
          style={{
            ...provided.draggableProps.style,
            background: snapshot.isDragging ? 'lightgreen' : 'white',
          }}
        >
          <div
            {...provided.dragHandleProps}
            className="cursor-move pr-4 flex items-center h-full"
          >
            <GripVertical size={20} className="text-gray-400" />
          </div>
          <div className="flex-grow">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-medium text-left">{question.text}</h3>
              <div className="flex space-x-2">
                <button
                  onClick={() => onEdit(index)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <Edit size={20} />
                </button>
                <button
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this question?')) {
                      removeQuestion(index);
                    }
                  }}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
            <div className="text-left">{renderQuestionInput(question)}</div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default QuestionPreview;