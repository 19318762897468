import React, { useState, useEffect, forwardRef } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { db } from '../firebase/config';
import { doc, getDoc, setDoc, addDoc, collection } from 'firebase/firestore';
import QuestionDesigner from './QuestionDesigner';
import QuestionPreview from './QuestionPreview';
import { Save, BarChart, Share } from 'lucide-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { auth } from '../firebase/config'; // Added import for auth
import { StrictModeDroppable } from '../utils/StrictModeDroppable';

const generateDefaultTitle = () => {
  return `New Survey (${new Date().toLocaleDateString()})`;
};

const SurveyDesigner = forwardRef(({ createNewSurvey, setSurveyId, user }, ref) => {
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState({ title: generateDefaultTitle(), questions: [] });
  const [loading, setLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState('idle');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [shareButtonShown, setShareButtonShown] = useState(false);
  const [previewResponses, setPreviewResponses] = useState({});
  const [editingQuestion, setEditingQuestion] = useState(null);

  const handlePreviewResponseChange = (questionId, value) => {
    setPreviewResponses(prev => ({
      ...prev,
      [questionId]: value
    }));
  };

  useEffect(() => {
    const fetchSurvey = async () => {
      if (!surveyId || surveyId === 'new') {
        setSurvey({ title: generateDefaultTitle(), questions: [] });
        setIsAuthorized(true);
        setLoading(false);
        return;
      }
      try {
        const surveyDoc = await getDoc(doc(db, 'surveys', surveyId));
        if (surveyDoc.exists()) {
          const surveyData = surveyDoc.data();
          if (surveyData.userId === auth.currentUser?.uid) {
            setSurvey({
              ...surveyData,
              questions: surveyData.questions || []
            });
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
        } else {
          console.error('Survey not found');
          setIsAuthorized(false);
        }
      } catch (error) {
        console.error('Error fetching survey:', error);
        setIsAuthorized(false);
      } finally {
        setLoading(false);
      }
    };
    fetchSurvey();
  }, [surveyId]);

  useEffect(() => {
    if (survey.questions.length > 0 && surveyId && surveyId !== 'new' && !shareButtonShown) {
      setShareButtonShown(true);
    }
  }, [survey.questions, surveyId, shareButtonShown]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthorized) {
    navigate('/signin');
    return null;
  }

  const handleTitleChange = (e) => {
    setSurvey({ ...survey, title: e.target.value });
  };

  const addQuestion = (newQuestion) => {
    const questionWithId = { ...newQuestion, id: Date.now().toString() };
    setSurvey(prevSurvey => ({
      ...prevSurvey,
      questions: [...prevSurvey.questions, questionWithId]
    }));
  };

  const removeQuestion = (index) => {
    setSurvey(prevSurvey => ({
      ...prevSurvey,
      questions: prevSurvey.questions.filter((_, i) => i !== index)
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedQuestions = Array.from(survey.questions);
    const [reorderedItem] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, reorderedItem);

    setSurvey(prevSurvey => ({
      ...prevSurvey,
      questions: reorderedQuestions
    }));
  };

  const saveSurvey = async () => {
    setSaveStatus('saving');
    try {
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      const surveyData = {
        title: survey.title,
        questions: survey.questions.map(question => ({
          id: question.id,
          type: question.type,
          text: question.text,
          options: question.options && question.options.length > 0 ? question.options : [],
          minRating: question.minRating || null,
          maxRating: question.maxRating || null,
        })),
        updatedAt: new Date(),
        userId: auth.currentUser.uid,
      };
      console.log('Saving survey data:', JSON.stringify(surveyData, null, 2));

      if (!surveyId || surveyId === 'new') {
        const docRef = await addDoc(collection(db, 'surveys'), {
          ...surveyData,
          createdAt: new Date(),
        });
        console.log('New survey created with ID:', docRef.id);
        navigate(`/surveys/${docRef.id}/edit`);
      } else {
        await setDoc(doc(db, 'surveys', surveyId), surveyData);
        console.log('Existing survey updated with ID:', surveyId);
      }
      setSaveStatus('saved');
      setTimeout(() => setSaveStatus('idle'), 2000);
    } catch (error) {
      console.error('Error saving survey:', error);
      console.error('Error details:', error.code, error.message);
      alert(`Failed to save survey: ${error.message}. Please try again.`);
      setSaveStatus('idle');
    }
  };

  const analyzeResponses = () => {
    navigate(`/surveys/${surveyId}/analytics`);
  };

  const handleShareSurvey = () => {
    const shareUrl = `${window.location.origin}/s/${surveyId}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('Survey link copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
      alert('Failed to copy survey link. Please try again.');
    });
  };

  const handleEditQuestion = (index) => {
    setEditingQuestion(survey.questions[index]);
  };

  const updateQuestion = (updatedQuestion) => {
    setSurvey(prevSurvey => ({
      ...prevSurvey,
      questions: prevSurvey.questions.map(q => 
        q.id === updatedQuestion.id ? {
          id: updatedQuestion.id,
          type: updatedQuestion.type,
          text: updatedQuestion.text,
          options: updatedQuestion.options && updatedQuestion.options.length > 0 ? updatedQuestion.options : [],
          minRating: updatedQuestion.minRating || null,
          maxRating: updatedQuestion.maxRating || null,
        } : q
      )
    }));
    setEditingQuestion(null);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center justify-between mb-6">
        <input
          type="text"
          value={survey.title}
          onChange={handleTitleChange}
          placeholder="Survey Title"
          className="w-1/3 text-3xl font-bold p-2 border-b-2 border-cool-blue-300 focus:outline-none focus:border-cool-blue-500"
        />
        <div className="flex space-x-4">
          <button
            onClick={saveSurvey}
            disabled={saveStatus === 'saving'}
            className={`flex items-center justify-center px-4 py-2 rounded-md text-white transition duration-300 ${
              saveStatus === 'saving' ? 'bg-cool-blue-400 cursor-not-allowed' : 
              saveStatus === 'saved' ? 'bg-green-500 hover:bg-green-600' : 
              'bg-cool-blue-600 hover:bg-cool-blue-700'
            }`}
          >
            <Save size={20} className={`mr-2 ${saveStatus === 'saving' ? 'animate-spin' : ''}`} />
            {saveStatus === 'saving' ? 'Saving...' : saveStatus === 'saved' ? 'Saved!' : 'Save Survey'}
          </button>
          {survey.questions.length > 0 && surveyId && surveyId !== 'new' && (
            <button
              onClick={handleShareSurvey}
              className={`flex items-center justify-center px-4 py-2 rounded-md text-white bg-emerald-500 hover:bg-emerald-600 transition duration-300 ${
                shareButtonShown ? 'animate-popup' : 'opacity-0'
              }`}
            >
              <Share size={20} className="mr-2" />
              Share Survey
            </button>
          )}
          <button
            onClick={analyzeResponses}
            className="flex items-center justify-center px-4 py-2 rounded-md text-white bg-purple-500 hover:bg-purple-600 transition duration-300"
          >
            <BarChart size={20} className="mr-2" />
            Analytics
          </button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-6">
        <div className="w-full md:w-1/3">
          <QuestionDesigner 
            addQuestion={addQuestion} 
            editingQuestion={editingQuestion}
            updateQuestion={updateQuestion}
          />
        </div>
        <div className="w-full md:w-2/3">
          <h2 className="text-2xl font-semibold mb-4">Survey Preview</h2>
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="questions">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                  {survey.questions.map((question, index) => (
                    <QuestionPreview
                      key={question.id}
                      question={question}
                      index={index}
                      removeQuestion={removeQuestion}
                      isPreview={true}
                      handleResponseChange={handlePreviewResponseChange}
                      responses={previewResponses}
                      onEdit={() => handleEditQuestion(index)}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
});

export default SurveyDesigner;